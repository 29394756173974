body {
  background-color: #121212;
}

a.active button {
  background-color: rgba(144, 202, 249, 0.08);
}
a:active,
a {
  text-decoration: none;
}
